<template>
  <article class="view-subscription bg-wave-gradient">
    <div class="site-wrapper rythm-v-l">
      <div class="rythm-v flex-row flex-center-v flex-center-h">
        <div class="form-wrapper card shadow rythm-v">
          <h1>Votre abonnement</h1>

          <div v-if="success === true">
            <p><strong>Félicitations, vous êtes abonné à Infovac !</strong></p>
            <p>
              <router-link to="/dashboard" class="button--primary"
                >Tableau de bord</router-link
              >
            </p>
          </div>

          <div v-else>
            <p><strong>Vous avez annulé l’opération.</strong></p>
            <p>
              Rendez-vous sur votre compte utilisateur pour souscrire à
              Infovac .
            </p>
            <p>
              <router-link
                :to="`/user/${user.id}/profile`"
                class="button--primary"
                >Mon compte</router-link
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<style lang="scss" scoped>
.form-wrapper {
  flex-basis: 40rem;
}
</style>

<script>
export default {
  name: "UserValidation",

  data: function() {
    return {
      userData: null,
      success: false,
      session_id: "",
    };
  },

  computed: {
    // Current user data
    user: function() {
      return this.$store.state.userData;
    },
  },

  methods: {
    // Load user data from database
    refreshUser: function() {
      this.$store.dispatch("REFRESH_USER", this.user.id);
    },
  },

  mounted() {
    // Refresh User Data to get new subscription info
    this.refreshUser();

    if (
      this.$route.params.pathMatch !== "/success" &&
      this.$route.params.pathMatch !== "/canceled"
    ) {
      this.$router.push("/dashboard");
    }

    if (this.$route.params.pathMatch === "/success") {
      this.success = true;
      this.session_id = this.$route.query.session_id; // Stripe Checkout Session ID
    } else if (this.$route.params.pathMatch === "/canceled") {
      this.success = false;
    }
  },
};
</script>
